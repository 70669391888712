import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store/store.js";
import App from "./App";
import { LoaderProvider } from "./Reducers/LoaderProvider.js";
import GlobalLoader from "./component/GlobalLoader.jsx";

// Load Facebook Pixel
(function (f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  };
  if (!f._fbq) f._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = "2.0";
  n.queue = [];
  t = b.createElement(e);
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s);
})(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

// Initialize Pixel with your Pixel ID
window.fbq("init", "914637010864680");
window.fbq("track", "PageView");

// Add Facebook domain verification meta tag
<meta name="facebook-domain-verification" content="r0jd133ozegi2kkdwlyhk84luwqs0v" />




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <LoaderProvider>
      <App />
      <GlobalLoader />
    </LoaderProvider>
  </Provider>
);
